<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Privacy Policy</h2>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Service details -->
<div class="service-details pb-100 pt-70">
    <div class="container">
        <div class="service-details-content">
            <h3>General</h3>
            <p>Al acceder al sitio qa.orangefi.us.com el usuario esta aceptando y reconoce que ha revisado y esta de acuerdo con su Política de Privacidad.<br>
               OrangeFI, se reserva el derecho a modificar la presente política de privacidad y será responsabilidad del usuario la lectura y acatamiento de esta cada vez que ingrese al sitio.</p>
            <br>

            <h4>Acceso a la Información</h4>
            <p>Los contenidos del sitio qa.orangefi.us.com tienen carácter gratuito para los usuarios, sin embargo hay información que está limitada para miembros de la comunidad ExpertChoice.</p>
            <p>Para acceder a ellos los usuarios miembros de la comunidad podrán acceder con los nombres de usuario y claves que les correspondan en su calidad de miembros.</p>
            <br>
            
            <h4>Información de los usuarios</h4>
            <p>OrangeFI, recopila datos de los suscriptores, usuarios y/o visitantes que hagan uso de este portal. Esto puede ser a través de procesos informáticos para realizar registros de actividades (patrones de actividad, navegación y audiencia). Para ellos no será necesaria la identificación personal de usuarios y/o visitantes.</p>
            <p>También podrán ser requeridos expresamente datos de usuarios o visitantes del sitio qa.orangefi.us.com. La entrega de esta información será voluntaria y se indicará claramente el fin para el cual está siendo solicitada.</p>
            <br>
            
            <h4>Información a terceros</h4>
            <p>OrangeFI, no comunicará ni transferirá a terceros los datos personales de sus usuarios sin el consentimiento expreso del titular. No obstante lo anterior, en caso de ser requerido judicialmente se hará entrega de la información solicitada.</p>
            <br>
            
            <h4>Uso de la información</h4>
            <p>Todos los derechos referidos a qa.orangefi.us.com y sus contenidos, incluidos los de propiedad intelectual, pertenecen a OrangeFI</p>
            <p>Al acceder al sitio, el visitante tendrá derecho a revisar toda la información que esté disponible en él, sólo pudiendo utilizarla para fines particulares y no comerciales. Sin perjuicio de lo anterior, Expert Choice S. A no se hace responsable por la veracidad o exactitud de la información contenida en los enlaces a otros sitios Web o que haya sido entregada por terceros.</p>
            <p>OrangeFI, autoriza la utilización o reproducción total o parcial de los contenidos e información de su portal, indicando claramente su fuente u origen.</p>

        </div>
    </div>
</div>
<!-- End Service details -->