<!-- Start Footer Area -->
<footer
    class="footer-area"
    [ngClass]="{'d-none': router.url === '/home-nine' || router.url === '/home-ten' || router.url === '/home-eleven'}"
>
    <div class="divider"></div>
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/white-logo.png" alt="image">
                        </a>
                    </div>
                    <p>Lemon FI es una empresa TANTAUCO, utiliza tecnologías Expert Choice S.A., y cuyo soporte tecnológico en la región es brindado por Expert Choice US LLC.</p>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Company</h3>
                    <ul class="services-list">
                        <li><a href="/#Servicios">Servicios</a></li>
                        <li><a href="/#Clientes">Clientes</a></li>
                        <li><a href="/#Tecnologias">Tecnologías</a></li>
                        <li><a href="/#Noticias">Noticias</a></li>
                        <li><a href="/#Contacto">Contacto</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Support</h3>
                    <ul class="support-list">
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/terminos">Terms & Conditions</a></li>
                        <li><a href="/#Contacto">Contact Us</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contact Info</h3>
                    <ul class="footer-contact-info">
                        <li>Location: <a href="https://goo.gl/maps/EvX5BRRv72xLPfER8" target="_blank">333 SE 2nd Ave Suite #2000 Miami, FL 33131, United States of America</a></li>
                        <li>Email: <a href="mailto:hello@orangefi.click">hello@orangefi.click</a></li>
                        <li>Phone: <a href="tel:+17864327801">+1786-432-7801</a></li>
                    </ul>
                    <ul class="social">
                        <li><a href="https://www.facebook.com/ExpertchoiceChile" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="https://twitter.com/ExpertChoice_" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/22296454/" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <p>Copyright @{{currentYear}} Orangefi es una empresa <a href="http://inversionestantauco.com/" target="_blank">TANTAUCO</a>. Designed by <a href="https://expertchoice.tech/" target="_blank">Expert Choice Marketing</a></p>
        </div>
    </div>
</footer>

<footer
    class="footer-area-with-black-color pt-100 d-none"
    [ngClass]="{'d-block': router.url === '/home-nine' || router.url === '/home-ten' || router.url === '/home-eleven'}"
>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/white-logo.png" alt="image">
                        </a>
                    </div>
                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore.</p>
                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget ps-5">
                    <h3>Company</h3>
                    <ul class="services-list">
                        <li><a routerLink="/about">About Us</a></li>
                        <li><a routerLink="/services-1">Services</a></li>
                        <li><a routerLink="/features-1">Features</a></li>
                        <li><a routerLink="/pricing">Our Pricing</a></li>
                        <li><a routerLink="/blog-grid">Latest News</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Support</h3>
                    <ul class="support-list">
                        <li><a routerLink="/faq">FAQ's</a></li>
                        <li><a routerLink="/">Privacy Policy</a></li>
                        <li><a routerLink="/">Terms & Conditions</a></li>
                        <li><a routerLink="/about">Community</a></li>
                        <li><a routerLink="/contact">Contact Us</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contact Info</h3>
                    <ul class="footer-contact-info">
                        <li>Location: <a href="https://goo.gl/maps/MQ78iGP5g9VgZcJ38" target="_blank">6th Floor, Elegant Tower, York, USA</a></li>
                        <li>Email: <a href="mailto:hello@spacle.com">hello@spacle.com</a></li>
                        <li>Phone: <a href="tel:+17864327801">+1786-432-7801</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <p>Copyright @{{currentYear}} Lemon FI es una empresa <a href="http://inversionestantauco.com/" target="_blank">TANTAUCO</a>. Designed by <a href="https://expertchoice.tech/" target="_blank">Expert Choice Marketing</a></p>
        </div>
    </div>
    <div class="footer-shape-1">
        <img src="assets/img/seo-marketing/footer-shape1.png" alt="image">
    </div>
    <div class="footer-shape-2">
        <img src="assets/img/seo-marketing/footer-shape2.png" alt="image">
    </div>
</footer>
<!-- End Footer Area -->

<div class="go-top"><i class='bx bx-chevron-up'></i></div>