import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-six',
  templateUrl: './home-six.component.html',
  styleUrls: ['./home-six.component.scss']
})
export class HomeSixComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
