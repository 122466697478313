<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Frequently Asked Questions</h2>
            <p>Get to know about Spacle</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start FAQ Area -->
<section class="faq-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion">
                    <ul class="accordion">
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                What access do I have on the free plan?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                What access do I have on a free trial?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                Does the price go up as my team gets larger?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                How can I cancel/pause my subscription?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                Can I pay via invoicing?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                What is a paid conversation?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="bx bx-plus"></i>
                                How can I get an invoice/a receipt?
                            </a>
                            <p class="accordion-content">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/faq-img1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End FAQ Area -->

<!-- Start Subscribe Area -->
<section class="subscribe-area bg-f4f5fe">
    <div class="container">
        <div class="subscribe-content">
            <h2>We always try to be as close to you as possible</h2>

            <form class="newsletter-form">
                <div class="row align-items-center">
                    <div class="col-lg-8 col-md-8">
                        <input type="email" class="input-newsletter" placeholder="hello@spacle.com" name="EMAIL">
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <button type="submit"><i class="bx bxs-hot"></i> Subscribe Now</button>
                    </div>
                </div>
            </form>

            <div class="shape14"><img src="assets/img/shape/13.png" alt="image"></div>
            <div class="shape15"><img src="assets/img/shape/14.png" alt="image"></div>
            <div class="shape16"><img src="assets/img/shape/15.png" alt="image"></div>
            <div class="shape17"><img src="assets/img/shape/16.png" alt="image"></div>
            <div class="shape18"><img src="assets/img/shape/17.png" alt="image"></div>
        </div>
    </div>
</section>
<!-- End Subscribe Area -->

<!-- Start Free Trial Area -->
<section class="free-trial-area ptb-100 bg-f4f5fe">
    <div class="container">
        <div class="free-trial-content">
            <h2>Practice active listening and follow through</h2>
            <p>Qualify your leads & recognize the value of word your customer will love you</p>
            <a routerLink="/" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now <span></span></a>
        </div>
    </div>

    <div class="shape10"><img src="assets/img/shape/10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/7.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/11.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/12.png" alt="image"></div>
</section>
<!-- End Free Trial Area -->