<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Contact Us</h2>
            <p>Drop us Message for any Query</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section class="contact-area ptb-100">
    <div class="container">
        <div class="contact-inner">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-features-list">
                        <h3>Some other great reasons to choose us</h3>
                        <p>Take the first steps towards increased productivity and reduced stress with Spacle.</p>
                        <p>We are a highly trained, dedicated team, helping entrepreneurs, professionals, and small teams streamline success - not just in work, but in life too. We help spare your time to focus on the bigger picture by taking care of the little details.</p>
                        <ul>
                            <li><i class='bx bx-badge-check'></i> Staff works from our offices in USA</li>
                            <li><i class='bx bx-badge-check'></i> Scale up/ down on demand</li>
                            <li><i class='bx bx-badge-check'></i> One time or on going projects</li>
                            <li><i class='bx bx-badge-check'></i> Hire based on skill set</li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <h3>Let's Start Your Free Trial</h3>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" placeholder="Your Name">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" placeholder="Your Email">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Your Phone">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Your Subject">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="6" placeholder="Your Message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn"><i class='bx bxs-paper-plane'></i>Send Message<span></span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="contact-info">
                <div class="contact-info-content">
                    <h3>Contact us by Phone Number or Email Address</h3>
                    <h2>
                        <a href="tel:+0881306298615">+088 130 629 8615</a>
                        <span>OR</span>
                        <a href="mailto:hello@spacle.com">hello@spacle.com</a>
                    </h2>
                    <ul class="social">
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-youtube"></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Area -->

<!-- Start Our Loving Clients Area -->
<section class="our-loving-clients ptb-100 bg-f4f5fe">
    <div class="container">
        <div class="section-title">
            <h2>Our Loving Clients</h2>
        </div>

        <div class="clients-logo-list align-items-center">
            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients1.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients2.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients3.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients4.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients5.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients6.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients7.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients8.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="#" target="_blank">
                    <img src="assets/img/clients-image/clients9.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</section>
<!-- End Our Loving Clients Area -->