import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-features-one',
  templateUrl: './features-one.component.html',
  styleUrls: ['./features-one.component.scss']
})
export class FeaturesOneComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
