<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Services Style Two</h2>
            <p>Our Services</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Area -->
<section class="features-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class='bx bx-conversation'></i>
                    </div>
                    <h3>IT Consultancy</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                    <a routerLink="/single-services" class="read-more">
                        Read more 
                        <i class='bx bx-right-arrow-alt'></i>
                    </a>

                    <div class="back-icon">
                        <i class='bx bx-conversation'></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class='bx bx-mobile'></i>
                    </div>
                    <h3>Mobile Applications</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                    <a routerLink="/single-services" class="read-more">
                        Read more 
                        <i class='bx bx-right-arrow-alt'></i>
                    </a>

                    <div class="back-icon">
                        <i class='bx bx-mobile'></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class='bx bxs-badge-check'></i>
                    </div>
                    <h3>IT Solutions</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                    <a routerLink="/single-services" class="read-more">
                        Read more 
                        <i class='bx bx-right-arrow-alt'></i>
                    </a>

                    <div class="back-icon">
                        <i class='bx bxs-badge-check'></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class='bx bx-laptop'></i>
                    </div>
                    <h3>Web Development</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                    <a routerLink="/single-services" class="read-more">
                        Read more 
                        <i class='bx bx-right-arrow-alt'></i>
                    </a>

                    <div class="back-icon">
                        <i class='bx bx-laptop'></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class='bx bx-cart'></i>
                    </div>
                    <h3>eCommerce</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                    <a routerLink="/single-services" class="read-more">
                        Read more 
                        <i class='bx bx-right-arrow-alt'></i>
                    </a>

                    <div class="back-icon">
                        <i class='bx bx-cart'></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class='bx bxs-dashboard'></i>
                    </div>
                    <h3>Project Management</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                    <a routerLink="/single-services" class="read-more">
                        Read more 
                        <i class='bx bx-right-arrow-alt'></i>
                    </a>

                    <div class="back-icon">
                        <i class='bx bxs-dashboard'></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class='bx bx-data'></i>
                    </div>
                    <h3>Web Hosting</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                    <a routerLink="/single-services" class="read-more">
                        Read more 
                        <i class='bx bx-right-arrow-alt'></i>
                    </a>

                    <div class="back-icon">
                        <i class='bx bxs-bell-ring'></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class='bx bxs-info-circle'></i>
                    </div>
                    <h3>Technical Support</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                    <a routerLink="/single-services" class="read-more">
                        Read more 
                        <i class='bx bx-right-arrow-alt'></i>
                    </a>

                    <div class="back-icon">
                        <i class='bx bxs-info-circle'></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="features-box">
                    <div class="icon">
                        <i class='bx bx-cog'></i>
                    </div>
                    <h3>SEO, Affiliate Marketing</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                    <a routerLink="/single-services" class="read-more">
                        Read more 
                        <i class='bx bx-right-arrow-alt'></i>
                    </a>

                    <div class="back-icon">
                        <i class='bx bx-cog'></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->

<!-- Start Subscribe Area -->
<section class="subscribe-area bg-f4f5fe">
    <div class="container">
        <div class="subscribe-content">
            <h2>We always try to be the best support to you as possible</h2>

            <form class="newsletter-form">
                <div class="row align-items-center">
                    <div class="col-lg-8 col-md-8">
                        <input type="email" class="input-newsletter" placeholder="hello@spacle.com" name="EMAIL">
                    </div>

                    <div class="col-lg-4 col-md-4">
                        <button type="submit"><i class="bx bxs-hot"></i> Subscribe Now</button>
                    </div>
                </div>
            </form>

            <div class="shape14"><img src="assets/img/shape/13.png" alt="image"></div>
            <div class="shape15"><img src="assets/img/shape/14.png" alt="image"></div>
            <div class="shape16"><img src="assets/img/shape/15.png" alt="image"></div>
            <div class="shape17"><img src="assets/img/shape/16.png" alt="image"></div>
            <div class="shape18"><img src="assets/img/shape/17.png" alt="image"></div>
        </div>
    </div>
</section>
<!-- End Subscribe Area -->

<app-clients></app-clients>