import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { EmailService } from 'src/app/services/email.service';

@Component({
  selector: 'app-home-nine',
  templateUrl: './home-nine.component.html',
  styleUrls: ['./home-nine.component.scss']
})
export class HomeNineComponent implements OnInit {

  constructor(private emailService: EmailService) { }

  correo: FormControl;
  mensaje: FormControl;
  notificacion: String;
  ngOnInit(): void {
    this.correo = new FormControl('', Validators.required);
    this.mensaje = new FormControl('', Validators.required);
    this.notificacion = '';
  }

  enviarCorreo() {
    if (this.correo.value === '') {
      this.notificacion = 'Correo no puede estar vacio';
    } else if (this.mensaje.value === '') {
      this.notificacion = 'Mensaje no puede estar vacio';
    } else {
      const contenido = "Un cliente a enviado la siguiente información: <br><br>"
        + "Correo: " + this.correo.value + "<br>"
        + "Mensaje: " + this.mensaje.value + "<br>"
        + "<br><br>"
        + "-- Este E-mail se ha enviado desde un formulario de contacto del sitio Web de OrangeFI (https://www.orangefi.click/)";
      const body = {
        "from": "hello@orangefi.click",
        "to": "hello@orangefi.click",
        "subject": "Contacto para información",
        "content": contenido
      };

      this.emailService.enviarCorreo(body).subscribe(data => {
        if (data.status == 200) {
          this.notificacion = 'Correo Enviado';
        } else {
          this.notificacion = 'Error de envio de Correo';
        }
      },
        error => {
          console.log(error);
        })

      this.correo = new FormControl('', Validators.required);
      this.mensaje = new FormControl('', Validators.required);
    }
  }

}
