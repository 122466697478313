<!-- Start Partner Area -->
<section class="partner-area pt-100 pb-70 bg-f8fbfa">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-12">
                <div class="partner-title">
                    <h3>Featured by:</h3>
                </div>
            </div>

            <div class="col-lg-9 col-md-12">
                <div class="partner-slides owl-carousel owl-theme">
                    <div class="single-partner-item">
                        <a href="#" target="_blank">
                            <img src="assets/img/partner-image/partner1.png" alt="image">
                        </a>
                    </div>

                    <div class="single-partner-item">
                        <a href="#" target="_blank">
                            <img src="assets/img/partner-image/partner2.png" alt="image">
                        </a>
                    </div>

                    <div class="single-partner-item">
                        <a href="#" target="_blank">
                            <img src="assets/img/partner-image/partner3.png" alt="image">
                        </a>
                    </div>

                    <div class="single-partner-item">
                        <a href="#" target="_blank">
                            <img src="assets/img/partner-image/partner4.png" alt="image">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Partner Area -->