<div class="seo-marketing-home-with-full-background">

    <!-- Start SEO Marketing Banner Area -->
    <section class="seo-marketing-banner">
        <div class="container-fluid">
            <div class="row justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="seo-marketing-banner-content">
                        <p>AI Seller Machine</p>
                        <h1>Somos la Cobranza 100% IA de la Industria Financiera</h1>
                        <p>Nuestra plataforma, dotada de IA, perfecciona la gestión de cobranzas mediante el motor analítico avanzado y modelos de predicción de comportamiento de pago, asegurando una recuperación de deuda efectiva y respetuosa.</p>
                        <ul class="banner-btn">
                            <li>
                                <a routerLink="/" fragment="Contacto" class="default-btn">
                                    <i class="bx bxs-hot"></i>Conversemos!<span></span>
                                </a>
                            </li>
                            <!--<li>
                                <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"> 
                                    <i class='bx bxs-right-arrow'></i> Watch Video 
                                </a>
                            </li>-->
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="seo-marketing-banner-image">
                        <img src="assets/img/seo-marketing/banner/main.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
        <div class="seo-marketing-banner-shape-1">
            <img src="assets/img/seo-marketing/banner/shape1.png" alt="image">
        </div>
        <div class="seo-marketing-banner-shape-2">
            <img src="assets/img/seo-marketing/banner/shape2.png" alt="image">
        </div>
        <div class="seo-marketing-banner-shape-3">
            <img src="assets/img/seo-marketing/banner/shape3.png" alt="image">
        </div>
    </section>
    <!-- End SEO Marketing Banner Area -->

    <!-- Start SM Overview Area -->
    <div class="sm-overview-area pb-70">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-sm-6">
                    <div class="sm-overview-card">
                        <div class="image-icon">
                            <img src="assets/img/seo-marketing/overview/sales.png" alt="image">
                        </div>
                        <h3>Maximice la recuperación</h3>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <div class="sm-overview-card">
                        <div class="image-icon">
                            <img src="assets/img/seo-marketing/overview/usability.png" alt="image">
                        </div>
                        <h3>Reduzca los costos</h3>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <div class="sm-overview-card">
                        <div class="image-icon">
                            <img src="assets/img/seo-marketing/overview/web-traffic.png" alt="image">
                        </div>
                        <h3>Incremente la rentabilidad</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End SM Overview Area -->

    <!-- Start SM About Area -->
    <div class="sm-pricing-area pb-70" id="Servicios">
        <div class="container">
            <div class="section-title-with-large-box">
                <span>Pricing Plan</span>
                <h2>Nuestros Servicios</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="sm-pricing-card">
                        <div class="pricing-header">
                            <h3>Predicción y Personalización con IA</h3>
                        </div>
                        <ul class="pricing-features">
                            <li>
                                Implementación de inteligencia artificial para predecir comportamientos de pago y personalizar estrategias de cobranza, optimizando la recuperación de deudas.
                            </li>
                        </ul>
                        <div class="price-btn">
                            <a routerLink="/" fragment="Contacto" class="default-btn">Solicitar<span></span></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="sm-pricing-card">
                        <div class="pricing-header">
                            <h3>Análisis Detallado de Recuperación</h3>
                        </div>
                        <ul class="pricing-features">
                            <li>
                                Análisis profundo y seguimiento del rendimiento de las estrategias de cobranza a través del módulo 'Control Recupero', asegurando una gestión eficaz de la recuperación de créditos.
                            </li>
                        </ul>
                        <br />
                        <div class="price-btn">
                            <a routerLink="/" fragment="Contacto" class="default-btn">Solicitar<span></span></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="sm-pricing-card">
                        <div class="pricing-header">
                            <h3>Gestión Integral de Comunicaciones</h3>
                        </div>
                        <ul class="pricing-features">
                            <li>
                                Integraciones avanzadas de canales de comunicación, tanto inbound como outbound, para una gestión coordinada y eficiente de la cobranza, mejorando la interacción con los deudores.
                            </li>
                        </ul>
                        <div class="price-btn">
                            <a routerLink="/" fragment="Contacto" class="default-btn">Solicitar<span></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="sm-pricing-shape">
            <img src="assets/img/seo-marketing/pricing-shape.png" alt="image">
        </div>
    </div>
    <!-- End SM Features Area -->

    <!-- Start SM Features Area -->
    <div class="sm-features-area pb-70">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-sm-6">
                    <div class="sm-features-card">
                        <img src="assets/img/seo-marketing/features/analysis.png" alt="image">
                        <h3>
                            <a routerLink="/single-services">En Tiempo Real</a>
                        </h3>
                        <p>Utiliza módulos “Control Recupero" y "Seguimiento de Script" para seguimiento detallado de las estrategias de cobranza.</p>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="sm-features-card">
                        <img src="assets/img/seo-marketing/features/pay-per-click.png" alt="image">
                        <h3>
                            <a routerLink="/single-services">Machine Learning</a>
                        </h3>
                        <p>Incorpora "Generador de Modelos" y módulos de IA potenciando las estrategias de cobranza mediante el uso de datos y predicciones precisas.</p>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 wow">
                    <div class="sm-features-card">
                        <img src="assets/img/seo-marketing/features/analytics.png" alt="image">
                        <h3>
                            <a routerLink="/single-services">Omnicanal</a>
                        </h3>
                        <p>Se integra a sus web, modales, rrss y responde por SMS, Whatsapp y Email, de acuerdo a
                            programación de árboles y trigger de envío.</p>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="sm-features-card">
                        <img src="assets/img/seo-marketing/features/digital-learning.png" alt="image">
                        <h3>
                            <a routerLink="/single-services">APIs</a>
                        </h3>
                        <p>Todos los servicios de Lemon Fi están apificados, para que usted pueda integrar a su workflow
                            de curse de forma inmediata.</p>
                        <p><br /></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End SM Features Area -->

    <!-- Start SM Work Area -->
    <div class="sm-work-area pb-70">
        <div class="container">
            <div class="row justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12 wow">
                    <div class="sm-work-content">
                        <h3>Nosotros trabajamos, para que tu disfrutes tu vida.</h3>
                        <div class="sm-work-inner-card">
                            <div class="number">
                                <span>01</span>
                            </div>
                            <h4>Respuestas 24x7</h4>
                            <p>Lemon Fi accesible 24/7 y con seguridad avanzada de AWS
                            </p>
                        </div>
                        <div class="sm-work-inner-card">
                            <div class="number">
                                <span>02</span>
                            </div>
                            <h4>Integración vía APIs</h4>
                            <p>Lemon Fi te permite poner a disposición para tus clientes completos Portales de Autoatención o
                                integrar su APIs a tus plataformas</p>
                        </div>
                        <div class="sm-work-inner-card">
                            <div class="number">
                                <span>03</span>
                            </div>
                            <h4>Calibración de Modelos</h4>
                            <p>Lemon Fi calibra regularmente sus modelos, para no perder estabilidad y predictibilidad,
                                vía Back Test y Stress Test</p>
                        </div>
                        <div class="sm-work-inner-card">
                            <div class="number">
                                <span>04</span>
                            </div>
                            <h4>Customizado LATAM</h4>
                            <p>Calibrado para todos los países de la región de forma de ayudarte a lograr mejores
                                evaluaciones, seguimiento y cobranza Robotizada</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="sm-work-image">
                        <img src="assets/img/seo-marketing/work.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End SM Work Area -->

    <!-- Start SM Funfacts Area -->
    <div class="sm-funfacts-area">
        <div class="container">
            <div class="sm-funfacts-inner-box pt-100 pb-70">
                <div class="row justify-content-center">
                    <div class="col-lg-3 col-6 col-sm-6 col-md-3 wow">
                        <div class="sm-single-funfacts">
                            <img src="assets/img/seo-marketing/funfacts/funfacts1.png" alt="image">
                            <h3>
                                <span class="odometer" data-count="800">00</span>
                                <span class="sign-icon">+</span>
                            </h3>
                            <p>Operaciones / Hora</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-6 col-sm-6 col-md-3">
                        <div class="sm-single-funfacts">
                            <img src="assets/img/seo-marketing/funfacts/funfacts2.png" alt="image">
                            <h3>
                                <span class="odometer" data-count="999">00</span>
                                <span class="sign-icon">+</span>
                            </h3>
                            <p>Fuentes de Datos</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-6 col-sm-6 col-md-3">
                        <div class="sm-single-funfacts">
                            <img src="assets/img/seo-marketing/funfacts/funfacts3.png" alt="image">
                            <h3>
                                <span class="odometer" data-count="120">00</span>
                                <span class="sign-icon">+</span>
                            </h3>
                            <p>Oficina Integrados</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-6 col-sm-6 col-md-3">
                        <div class="sm-single-funfacts">
                            <img src="assets/img/seo-marketing/funfacts/funfacts4.png" alt="image">
                            <h3>
                                <span class="odometer" data-count="70">00</span>
                                <span class="sign-icon">+</span>
                            </h3>
                            <p>Clientes</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End SM Funfacts Area -->

    <!-- Start SM FAQ Area -->
    <div class="sm-faq-area pb-100">
        <div class="container pt-100">
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-6 col-md-12">
                    <div class="sm-faq-image">
                        <img src="assets/img/seo-marketing/faq.png" alt="image">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="sm-faq-accordion">
                        <h2>Nuestros Resultados</h2>
                        <ul class="accordion">
                            <li class="accordion-item">
                                <a class="accordion-title active" href="javascript:void(0)">
                                    <i class="bx bx-plus"></i>
                                    Créditos en Bancos: Perú, Chile, Bolivia
                                </a>
                                <p class="accordion-content show">
                                    Implementación en página web del cliente banco de sistema de Onboarding, Evaluación,
                                    Curse y Formalización Remota de operaciones de crédito de consumo 100% online.
                                </p>
                            </li>
                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)">
                                    <i class="bx bx-plus"></i>
                                    eMarket Place Facturas - Retail en Ecuador
                                </a>
                                <p class="accordion-content">
                                    Sistema de financiamiento de facturas, financiadas por los principales bancos de
                                    Ecuador y emitidas por clientes de las principales cadenas de retailers del país.
                                </p>
                            </li>
                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)">
                                    <i class="bx bx-plus"></i>
                                    Motor De Decisiones - Leasing en Perú
                                </a>
                                <p class="accordion-content">
                                    Implementación de Motor Decisional para el Banco, integrado a todos sus sistemas,
                                    vía APIs, integrados a fuentes externas de datos, para sus productos de Créditos de
                                    Consumo, TCR y LCR.
                                </p>
                            </li>
                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)">
                                    <i class="bx bx-plus"></i>
                                    E-Factoring Online - Financiera en Argentina
                                </a>
                                <p class="accordion-content">
                                    Plataforma que permite a clientes autoatenderse; realizar el On Boarding digital,
                                    Cargar XML de Facturas, información tributaria y financiera para obtener una
                                    pre-evaluación en línea.
                                </p>
                            </li>
                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)">
                                    <i class="bx bx-plus"></i>
                                    Evaluación Renting - Financiera en Estados Unidos
                                </a>
                                <p class="accordion-content">
                                    Evalúa en la pagina web del Renting al cliente y entrega la mejor oferta de acuerdo
                                    a las reglas de decisión configuradas en el Motor Decisional genera documentos del
                                    arriendo y se perfecciona con FEA.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="sm-faq-shape">
            <img src="assets/img/seo-marketing/faq-shape.png" alt="image">
        </div>
    </div>
    <!-- End SM FAQ Area -->

    <!-- Start SM Partner Area -->
    <div class="sm-partner-area" id="Clientes">
        <div class="container">
            <div class="sm-partner-inner-box pt-100 pb-70">
                <div class="section-title-with-large-box">
                    <span>Confían En Nosotros</span>
                </div>
                <div class="row justify-content-center align-items-center">
                    <div class="col-lg-2 col-6 col-sm-3 col-md-4">
                        <div class="sm-partner-box">
                            <a href="#">
                                <!--<img src="assets/img/seo-marketing/partner/partner1.png" alt="image">-->
                                <img src="assets/img/seo-marketing/partner/logoPartner1.png" alt="image">
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-2 col-6 col-sm-3 col-md-4">
                        <div class="sm-partner-box">
                            <a href="#">
                                <!--<img src="assets/img/seo-marketing/partner/partner2.png" alt="image">-->
                                <img src="assets/img/seo-marketing/partner/logoPartner2.png" alt="image">
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-2 col-6 col-sm-3 col-md-4 wow">
                        <div class="sm-partner-box">
                            <a href="#">
                                <!--<img src="assets/img/seo-marketing/partner/partner3.png" alt="image">-->
                                <img src="assets/img/seo-marketing/partner/logoPartner3.png" alt="image">
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-2 col-6 col-sm-3 col-md-4">
                        <div class="sm-partner-box">
                            <a href="#">
                                <!--<img src="assets/img/seo-marketing/partner/partner4.png" alt="image">-->
                                <img src="assets/img/seo-marketing/partner/logoPartner4.png" alt="image" width="120">
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-2 col-6 col-sm-3 col-md-4">
                        <div class="sm-partner-box">
                            <a href="#">
                                <!--<img src="assets/img/seo-marketing/partner/partner5.png" alt="image">-->
                                <img src="assets/img/seo-marketing/partner/logoPartner5.png" alt="image" width="150">
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-2 col-6 col-sm-3 col-md-4">
                        <div class="sm-partner-box">
                            <a href="#">
                                <!--<img src="assets/img/seo-marketing/partner/partner6.png" alt="image">-->
                                <img src="assets/img/seo-marketing/partner/logoPartner6.png" alt="image">
                            </a>
                        </div>
                    </div>
                </div>
                <div class="sm-partner-shape">
                    <img src="assets/img/seo-marketing/partner/shape.png" alt="image">
                </div>
            </div>
        </div>
    </div>
    <!-- End SM Partner Area -->

    <!-- Start SM Services Area -->
    <div class="sm-services-area pt-100 pb-70" id="Tecnologias">
        <div class="container">
            <div class="section-title-with-large-box">
                <span>Introduce our services</span>
                <h2>Conoce todas las tecnologías que utiliza nuestro equipo, para lograr que tus procesos sean
                    expertos.</h2>
            </div>
            <div class="row justify-content-center align-items-center">
                <div class="col-lg-4 col-md-12">
                    <div class="sm-services-card wow">
                        <div class="content">
                            <div class="icon">
                                <i class="bx bx-conversation"></i>
                            </div>
                            <h3>
                                <a routerLink="/single-services">IA y Aprendizaje Automático</a>
                            </h3>
                            <p>Mejora la predicción de comportamientos de pago y la personalización de estrategias de cobranza.</p>
                        </div>
                    </div>
                    <div class="sm-services-card some-left">
                        <div class="content">
                            <div class="icon">
                                <i class="bx bx-customize"></i>
                            </div>
                            <h3>
                                <a routerLink="/single-services">AutoFlow</a>
                            </h3>
                            <p>Automatiza tareas repetitivas y procesos de toma de decisiones, incrementando la eficiencia operativa.</p>
                        </div>
                    </div>
                    <div class="sm-services-card">
                        <div class="content">
                            <div class="icon">
                                <i class="bx bx-slider-alt"></i>
                            </div>
                            <h3>
                                <a routerLink="/single-services">SegmentaSmart</a>
                            </h3>
                            <p>Segmentación detallada de clientes para una aproximación más personalizada en las acciones de cobranza.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="sm-services-card-image">
                        <img src="assets/img/seo-marketing/services.png" alt="image">
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="sm-services-card wow">
                        <div class="content">
                            <div class="icon">
                                <i class="bx bx-reset"></i>
                            </div>
                            <h3>
                                <a routerLink="/single-services">Omnicanalidad Digital</a>
                            </h3>
                            <p>Facilita la gestión coordinada de comunicaciones outbound e inbound a través de múltiples canales.</p>
                        </div>
                    </div>
                    <div class="sm-services-card some-right">
                        <div class="content">
                            <div class="icon">
                                <i class="bx bx-bell"></i>
                            </div>
                            <h3>
                                <a routerLink="/single-services">DataCob Insight</a>
                            </h3>
                            <p>Utiliza el análisis de Big Data para optimizar las estrategias de cobranza y mejorar la toma de decisiones.</p>
                        </div>
                    </div>
                    <div class="sm-services-card">
                        <div class="content">
                            <div class="icon">
                                <i class="bx bx-shape-circle"></i>
                            </div>
                            <h3>
                                <a routerLink="/single-services">SecureGuard</a>
                            </h3>
                            <p>Asegura la protección de datos sensibles y el cumplimiento con regulaciones a través de tecnologías de encriptación y protocolos de seguridad.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="sm-services-shape">
            <img src="assets/img/seo-marketing/services-shape.png" alt="image">
        </div>
    </div>
    <!-- End SM Services Area -->

    <!-- Start SM About Area -->
    <div class="sm-about-area pb-100">
        <div class="container">
            <div class="section-title-with-large-box">
                <span>About Company</span>
                <h2>El Team Lemon Fi, Siempre Listo para Apoyarte!</h2>
            </div>
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-6 col-md-12">
                    <div class="sm-about-image">
                        <img src="assets/img/seo-marketing/about/about.png" alt="image">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="sm-about-content">
                        <!--<h3>Grow your business with our seo agency</h3>
                        <p>Vivamus suscipit tortor eget felis porttitor volutpat nulla porttitor accumsan tincidunt proin eget tortor risus vivamus magna justo lacinia eget consectetur sed convallis at tellus.</p>-->
                        <div class="row justify-content-center">
                            <div class="col-lg-6 col-sm-6">
                                <ul class="list">
                                    <li><i class='bx bx-check'></i> Team Data Science, expertos en Estadística e IA.
                                    </li>
                                    <li><i class='bx bx-check'></i> Modelos de Venta, Créditos y Cobranzas Plug and
                                        Play.</li>
                                    <li><i class='bx bx-check'></i> Departamento jurídico. en MX, CL. CO, EC, US, AR, PE y
                                        Otros.</li>
                                </ul>
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <ul class="list">
                                    <li><i class='bx bx-check'></i> Equipo de desarrollo TI, con vasta experiencia
                                        financiera.</li>
                                    <li><i class='bx bx-check'></i> Departamento comercial, atento a tus necesidades.
                                    </li>
                                    <li><i class='bx bx-check'></i> Tenemos experiencia de más de 15 años integrando la
                                        data de America.</li>
                                </ul>
                            </div>
                        </div>
                        <div class="about-btn">
                            <a routerLink="/" fragment="Contacto" class="default-btn">
                                <i class="bx bxs-hot"></i>Hablemos!<span></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="sm-about-shape-1">
            <img src="assets/img/seo-marketing/about/shape1.png" alt="image">
        </div>
        <div class="sm-about-shape-2">
            <img src="assets/img/seo-marketing/about/shape2.png" alt="image">
        </div>
    </div>
    <!-- End SM About Area -->

    <!-- Start SM Websites Area -->
    <div class="sm-websites-area" id="Contacto">
        <div class="container">
            <div class="sm-websites-inner-area ptb-100">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="sm-websites-content">
                            <h3>Comunícate Ahora!</h3>
                            <p>Te enviaremos nuestra presentación de productos de robotización de procesos financieros
                                de forma inmediata.</p>
                            <form>
                                <div class="form-group">
                                    <label for="correo">Ingrese su email</label>
                                    <input type="text" [formControl]="correo" class="form-control" required
                                        placeholder="ejemplo@miempresa.com">
                                </div>
                                <div class="form-group">
                                    <label for="mensaje">¿En que los podemos ayudar?</label>
                                    <input type="text" class="form-control" [formControl]="mensaje" required
                                        placeholder="Bla... Bla... Bla...">
                                </div>
                                <div class="form-group">
                                    <span class="span-envio">{{notificacion}}</span>
                                </div>
                                <button type="button" (click)="enviarCorreo()" class="default-btn">Check
                                    Now<span></span></button>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="sm-websites-image">
                            <img src="assets/img/seo-marketing/websites/websites.png" alt="image">
                        </div>
                    </div>
                </div>
                <div class="websites-dot-shape">
                    <img src="assets/img/seo-marketing/websites/shape.png" alt="image">
                </div>
            </div>
        </div>
    </div>
    <!-- End SM Websites Area -->

    <!-- Start SM Blog Area -->
    <div class="sm-blog-area pt-100 pb-70">
        <div class="container">
            <div class="section-title-with-large-box">
                <span>Latest Blog</span>
                <h2>Valor Agregado que Ofrecemos Para ti</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="sm-blog-card">
                        <div class="blog-image">
                            <a routerLink="/blog/blog-details">
                                <img src="assets/img/seo-marketing/blog/blog1.jpg" alt="image">
                            </a>
                        </div>
                        <div class="blog-content">
                            <!--<ul class="meta">
                            <li>
                                <a href="https://cl.linkedin.com/in/nicolaslarrain" target="_blank">
                                    <img src="assets/img/user1.jpg" alt="image">
                                    Nicolás Larraín 
                                </a>
                            </li>
                            <li><i class='bx bx-calendar'></i> 04 Jul, 2022</li>
                        </ul>-->
                            <h3>
                                <a>Disfruta Tu Vida, Nosotros generamos procesos Expertos</a>
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="sm-blog-card">
                        <div class="blog-image">
                            <a>
                                <img src="assets/img/seo-marketing/blog/blog2.jpg" alt="image">
                            </a>
                        </div>
                        <div class="blog-content">
                            <!--<ul class="meta">
                            <li>
                                <a href="https://cl.linkedin.com/in/andrea-hern%C3%A1ndez-pardo-9a233b58" target="_blank">
                                    <img src="assets/img/user2.jpg" alt="image">
                                    Andrea Hernandez
                                </a>
                            </li>
                            <li><i class='bx bx-calendar'></i> 06 Jun, 2022</li>
                        </ul>-->
                            <h3>
                                <a>Machine Learning Para La Optimización de Tus Procesos</a>
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="sm-blog-card">
                        <div class="blog-image">
                            <a>
                                <img src="assets/img/seo-marketing/blog/blog3.jpg" alt="image">
                            </a>
                        </div>
                        <div class="blog-content">
                            <!--<ul class="meta">
                            <li>
                                <a href="https://cl.linkedin.com/in/rodrigoalvarezt" target="_blank">
                                    <img src="assets/img/user3.jpg" alt="image">
                                </a>
                            </li>
                            <li><i class='bx bx-calendar'></i> 18 Jul, 2021</li>
                        </ul> -->
                            <h3>
                                <a>Nuestros Servicios Vía APIs, te permiten Integrar Ahora </a>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="sm-blog-shape">
            <img src="assets/img/seo-marketing/blog/shape.png" alt="image">
        </div>
    </div>
    <!-- End SM Blog Area -->

    <!-- Start SM Subscribe Area -->
    <div class="sm-subscribe-area pb-70">
        <div class="container">
            <div class="sm-subscribe-inner-box ptb-100">
                <div class="section-title-with-large-box">
                    <!--<h2>Recibe todos los papers que preparamos regularmente para ti.</h2>-->
                    <h2>Disfruta de contenido único y personalizado con nuestro newsletter, diseñado para ti y entregado directamente en tu correo.</h2>
                </div>
                <form class="newsletter-form" data-bs-toggle="validator">
                    <div class="form-group">
                        <input type="email" class="input-newsletter" placeholder="ejemplo@miempresa.com" name="EMAIL"
                            required="" autocomplete="off">
                    </div>
                    <button type="submit" class="disabled" style="pointer-events: all; cursor: pointer;">
                        <i class='bx bx-send'></i>
                    </button>
                </form>
                <div class="sm-subscribe-shape-1">
                    <img src="assets/img/seo-marketing/subscribe-shape1.png" alt="image">
                </div>
                <div class="sm-subscribe-shape-2">
                    <img src="assets/img/seo-marketing/subscribe-shape2.png" alt="image">
                </div>
            </div>
        </div>
    </div>
    <!-- End SM Subscribe Area -->

    <!-- Start SM Pricing Area -->
    <!--<div class="sm-pricing-area pb-70" id="Precios">
        <div class="container">
            <div class="section-title-with-large-box">
                <span>Pricing Plan</span>
                <h2>Transparent pricing Plan</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="sm-pricing-card">
                        <div class="pricing-header">
                            <h3>Basic Plan</h3>
                        </div>
                        <div class="price">
                            $29 <span>/Per Month</span>
                        </div>
                        <ul class="pricing-features">
                            <li>
                                <i class='bx bx-check-circle'></i>
                                10 Pages responsive website
                            </li>
                            <li>
                                <i class='bx bx-check-circle'></i>
                                Audience research
                            </li>
                            <li>
                                <i class='bx bx-check-circle'></i>
                                Management & optimisation
                            </li>
                            <li>
                                <i class='bx bx-check-circle'></i>
                                50 SEO keywords
                            </li>
                            <li>
                                <i class='bx bx-check-circle'></i>
                                Google analytics tracking
                            </li>
                            <li>
                                <i class='bx bx-check-circle'></i>
                                Consultancy
                            </li>
                            <li>
                                <i class='bx bx-check-circle'></i>
                                24x7 Great support
                            </li>
                        </ul>
                        <div class="price-btn">
                            <a routerLink="/" class="default-btn">Select Plan<span></span></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="sm-pricing-card">
                        <div class="pricing-header">
                            <h3>Platinum Plan</h3>
                        </div>
                        <div class="price">
                            $49 <span>/Per Month</span>
                        </div>
                        <ul class="pricing-features">
                            <li>
                                <i class='bx bx-check-circle'></i>
                                10 Pages responsive website
                            </li>
                            <li>
                                <i class='bx bx-check-circle'></i>
                                Audience research
                            </li>
                            <li>
                                <i class='bx bx-check-circle'></i>
                                Management & optimisation
                            </li>
                            <li>
                                <i class='bx bx-check-circle'></i>
                                50 SEO keywords
                            </li>
                            <li>
                                <i class='bx bx-check-circle'></i>
                                Google analytics tracking
                            </li>
                            <li>
                                <i class='bx bx-check-circle'></i>
                                Consultancy
                            </li>
                            <li>
                                <i class='bx bx-check-circle'></i>
                                24x7 Great support
                            </li>
                        </ul>
                        <div class="price-btn">
                            <a routerLink="/" class="default-btn">Select Plan<span></span></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="sm-pricing-card">
                        <div class="pricing-header">
                            <h3>Advance Plan</h3>
                        </div>
                        <div class="price">
                            $99 <span>/Per Month</span>
                        </div>
                        <ul class="pricing-features">
                            <li>
                                <i class='bx bx-check-circle'></i>
                                10 Pages responsive website
                            </li>
                            <li>
                                <i class='bx bx-check-circle'></i>
                                Audience research
                            </li>
                            <li>
                                <i class='bx bx-check-circle'></i>
                                Management & optimisation
                            </li>
                            <li>
                                <i class='bx bx-check-circle'></i>
                                50 SEO keywords
                            </li>
                            <li>
                                <i class='bx bx-check-circle'></i>
                                Google analytics tracking
                            </li>
                            <li>
                                <i class='bx bx-check-circle'></i>
                                Consultancy
                            </li>
                            <li>
                                <i class='bx bx-check-circle'></i>
                                24x7 Great support
                            </li>
                        </ul>
                        <div class="price-btn">
                            <a routerLink="/" class="default-btn">Select Plan<span></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="sm-pricing-shape">
            <img src="assets/img/seo-marketing/pricing-shape.png" alt="image">
        </div>
    </div>-->
    <!-- End SM Pricing Area -->

</div>