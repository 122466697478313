<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Services</h2>
            <p>Our Services</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Area -->
<section class="features-area pt-100 pb-70 bg-f4f6fc">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="features-box-one">
                    <i class='bx bx-conversation bg-13c4a1'></i>
                    <h3>IT Consultancy</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna.</p>

                    <a routerLink="/single-services" class="read-more">
                        Read more 
                        <i class='bx bx-right-arrow-alt'></i>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="features-box-one">
                    <i class='bx bx-mobile bg-6610f2'></i>
                    <h3>Mobile Applications</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna.</p>

                    <a routerLink="/single-services" class="read-more">
                        Read more 
                        <i class='bx bx-right-arrow-alt'></i>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="features-box-one">
                    <i class='bx bxs-badge-check bg-ffb700'></i>
                    <h3>IT Solutions</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna.</p>

                    <a routerLink="/single-services" class="read-more">
                        Read more 
                        <i class='bx bx-right-arrow-alt'></i>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="features-box-one">
                    <i class='bx bx-laptop bg-9c27b0'></i>
                    <h3>Web Development</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna.</p>

                    <a routerLink="/single-services" class="read-more">
                        Read more 
                        <i class='bx bx-right-arrow-alt'></i>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="features-box-one">
                    <i class='bx bx-cart bg-3f51b5'></i>
                    <h3>eCommerce</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna.</p>

                    <a routerLink="/single-services" class="read-more">
                        Read more 
                        <i class='bx bx-right-arrow-alt'></i>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="features-box-one">
                    <i class='bx bxs-dashboard bg-e91e63'></i>
                    <h3>Project Management</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna.</p>

                    <a routerLink="/single-services" class="read-more">
                        Read more 
                        <i class='bx bx-right-arrow-alt'></i>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="features-box-one">
                    <i class='bx bx-data bg-fc3549'></i>
                    <h3>Web Hosting</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna.</p>

                    <a routerLink="/single-services" class="read-more">
                        Read more 
                        <i class='bx bx-right-arrow-alt'></i>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="features-box-one">
                    <i class='bx bxs-info-circle bg-00d280'></i>
                    <h3>Technical Support</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna.</p>

                    <a routerLink="/single-services" class="read-more">
                        Read more 
                        <i class='bx bx-right-arrow-alt'></i>
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="features-box-one">
                    <i class='bx bx-cog bg-ff612f'></i>
                    <h3>SEO, Affiliate Marketing</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna.</p>

                    <a routerLink="/single-services" class="read-more">
                        Read more 
                        <i class='bx bx-right-arrow-alt'></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->

<!-- Start Video Presentation Area -->
<section class="video-presentation-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Watch this video presentation to know more</h2>
        </div>

        <div class="video-box">
            <img src="assets/img/video-bg.jpg" class="main-image" alt="image">
            <a href="https://www.youtube.com/watch?v=0gv7OC9L2s8" class="video-btn popup-youtube"><i class="bx bx-play"></i></a>
            <div class="shape1"><img src="assets/img/shape/1.png" alt="image"></div>
            <div class="shape2"><img src="assets/img/shape/2.png" alt="image"></div>
            <div class="shape3"><img src="assets/img/shape/3.png" alt="image"></div>
            <div class="shape4"><img src="assets/img/shape/4.png" alt="image"></div>
            <div class="shape5"><img src="assets/img/shape/5.png" alt="image"></div>
            <div class="shape6"><img src="assets/img/shape/6.png" alt="image"></div>
        </div>

        <div class="funfacts-inner">
            <div class="row">
                <div class="col-lg-3 col-6 col-sm-3 col-md-3">
                    <div class="single-funfacts">
                        <h3><span class="odometer" data-count="180">00</span><span class="sign-icon">k</span></h3>
                        <p>Downloaded</p>
                    </div>
                </div>

                <div class="col-lg-3 col-6 col-sm-3 col-md-3">
                    <div class="single-funfacts">
                        <h3><span class="odometer" data-count="20">00</span><span class="sign-icon">k</span></h3>
                        <p>Feedback</p>
                    </div>
                </div>

                <div class="col-lg-3 col-6 col-sm-3 col-md-3">
                    <div class="single-funfacts">
                        <h3><span class="odometer" data-count="500">00</span><span class="sign-icon">+</span></h3>
                        <p>Workers</p>
                    </div>
                </div>

                <div class="col-lg-3 col-6 col-sm-3 col-md-3">
                    <div class="single-funfacts">
                        <h3><span class="odometer" data-count="70">00</span><span class="sign-icon">+</span></h3>
                        <p>Contributors</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="contact-cta-box">
            <h3>Have any question about us?</h3>
            <p>Don't hesitate to contact us.</p>
            <a routerLink="/contact" class="default-btn"><i class="bx bxs-edit-alt"></i>Contact Us<span></span></a>
        </div>
    </div>

    <div class="shape-map1"><img src="assets/img/map1.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/8.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape/9.png" alt="image"></div>
</section>
<!-- End Video Presentation Area -->

<app-pricing-plan></app-pricing-plan>

<!-- Start Team Area -->
<section class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Meet Our experts always ready to help you</h2>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/team1.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Alex Maxwel</h3>
                        <span>CEO & Founder</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/team2.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Sarah Taylor</h3>
                        <span>UX/UI Designer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/team3.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Lee Munroe</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team-image/team4.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Calvin Klein</h3>
                        <span>Support</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Team Area -->

<!-- Start App Download Area -->
<section class="app-download-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="app-download-image">
                    <img src="assets/img/mobile.png" alt="image">
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="app-download-content">
                    <span class="sub-title">Download App</span>
                    <h2>Supporting your customers on the go with our mobile app</h2>
                    <div class="btn-box">
                        <a href="#" class="apple-store-btn" target="_blank">
                            <img src="assets/img/applestore.png" alt="image">
                            Download on the
                            <span>Apple Store</span>
                        </a>
                        <a href="#" class="play-store-btn" target="_blank">
                            <img src="assets/img/playstore.png" alt="image">
                            Get it on
                            <span>Google Play</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End App Download Area -->

<!-- Start Feedback Area -->
<section class="feedback-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Whats Our Clients Said About <span>Spacle</span></h2>
        </div>

        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback-item">
                <img src="assets/img/woman1.png" alt="image">

                <div class="feedback-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>

                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>

                    <div class="client-info">
                        <h3>Sarah Taylor</h3>
                        <span>CEO at Envato</span>
                    </div>
                </div>
            </div>

            <div class="single-feedback-item">
                <img src="assets/img/woman2.png" alt="image">

                <div class="feedback-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>

                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>

                    <div class="client-info">
                        <h3>Olivar Lucy</h3>
                        <span>CEO at EnvyTheme</span>
                    </div>
                </div>
            </div>

            <div class="single-feedback-item">
                <img src="assets/img/man1.png" alt="image">

                <div class="feedback-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>

                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>

                    <div class="client-info">
                        <h3>Steven Smith</h3>
                        <span>CEO at Envato</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Start Feedback Area -->

<!-- Start Subscribe Area -->
<section class="subscribe-area bg-f4f5fe">
    <div class="container">
        <div class="subscribe-content">
            <h2>We always try to be the best support to you as possible</h2>

            <form class="newsletter-form">
                <div class="row align-items-center">
                    <div class="col-lg-8 col-md-8">
                        <input type="email" class="input-newsletter" placeholder="hello@spacle.com" name="EMAIL">
                    </div>

                    <div class="col-lg-4 col-md-4">
                        <button type="submit"><i class="bx bxs-hot"></i> Subscribe Now</button>
                    </div>
                </div>
            </form>

            <div class="shape14"><img src="assets/img/shape/13.png" alt="image"></div>
            <div class="shape15"><img src="assets/img/shape/14.png" alt="image"></div>
            <div class="shape16"><img src="assets/img/shape/15.png" alt="image"></div>
            <div class="shape17"><img src="assets/img/shape/16.png" alt="image"></div>
            <div class="shape18"><img src="assets/img/shape/17.png" alt="image"></div>
        </div>
    </div>
</section>
<!-- End Subscribe Area -->

<app-clients></app-clients>