<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Terms & Conditions</h2>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Service details -->
<div class="service-details pb-100 pt-70">
    <div class="container">
        <div class="service-details-content">
            <h3>Consentiemiento</h3>
            <p>Al utilizar nuestro sitio web, usted acepta nuestra Política de Privacidad y está de acuerdo con sus términos.</p>
            <br>

            <h4>Información que recopilamos</h4>
            <p>La información personal que se le pide, y las razones por las que se le pide, se le aclararán en el momento en que le pidamos que proporcione su información personal.</p>
            <p>Si se pone en contacto con nosotros directamente, podemos recibir información adicional sobre usted, como su nombre, dirección de correo electrónico, número de teléfono, el contenido del mensaje y/o los archivos adjuntos que nos envíe, y cualquier otra información que decida proporcionar.</p>
            <p>Cuando se registre en una cuenta, es posible que le pidamos su información de contacto, incluyendo elementos como el nombre, el nombre de la empresa, la dirección, la dirección de correo electrónico y el número de teléfono.</p>
            <br>
            
            <h4>Cómo Utilizamos su Información</h4>
            <p>Utilizamos la información que recopilamos de varias maneras, entre ellas.</p>
                <ul>
                    <li>Proporcionar, operar y mantener nuestro sitio web</li>
                    <li>Mejorar, personalizar y ampliar nuestro sitio web</li>
                    <li>Comprender y analizar cómo utiliza nuestro sitio web</li>
                    <li>Desarrollar nuevos productos, servicios, características y funcionalidades</li>
                    <li>Comunicarnos con usted, ya sea directamente o a través de uno de nuestros socios, incluso para el servicio de atención al cliente, para proporcionarle actualizaciones y otra información relacionada con el sitio web, y para fines de marketing y promoción</li>
                    <li>Enviarle correos electrónicos</li>
                    <li>Encontrar y prevenir el fraude</li>
                </ul>
            <br>

            <h4>Archivos de Registro</h4>
            <p>Inversiones Tantauco sigue un procedimiento estándar de uso de archivos de registro. Estos archivos registran a los visitantes cuando visitan los sitios web. Todas las empresas de alojamiento hacen esto y una parte de los análisis de los servicios de alojamiento. La información recopilada por los archivos de registro incluye las direcciones del protocolo de Internet (IP), el tipo de navegador, el proveedor de servicios de Internet (ISP), la fecha y la hora, las páginas de referencia/salida y, posiblemente, el número de clics. Estos datos no están vinculados a ninguna información que permita la identificación personal. El propósito de la información es analizar tendencias, administrar el sitio, rastrear el movimiento de los usuarios en el sitio web y recopilar información demográfica.</p>
            <br>
            
            <h4>Cookies y Balizas Web</h4>
            <p>Como cualquier otro sitio web, Inversiones Tantauco utiliza "cookies". Estas cookies se utilizan para almacenar información que incluye las preferencias de los visitantes y las páginas del sitio web a las que el visitante accedió o visitó. La información se utiliza para optimizar la experiencia de los usuarios mediante la personalización del contenido de nuestra página web en función del tipo de navegador de los visitantes y/u otra información.</p>
            <p>Para obtener más información general sobre las cookies, lea más en el sitio web de consentimiento de cookies.</p>
            <br>

        </div>
    </div>
</div>
<!-- End Service details -->