<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Services Style Three</h2>
            <p>Our Services</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Area -->
<section class="services-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="row m-0">
                        <div class="col-lg-6 col-md-12 p-0">
                            <div class="content">
                                <h3><a routerLink="/single-services">Social Media Marketing</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                                <a routerLink="/single-services" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 p-0">
                            <div class="image bg-1">
                                <img src="assets/img/marketing-agency/services-img1.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="row m-0">
                        <div class="col-lg-6 col-md-12 p-0">
                            <div class="content">
                                <h3><a routerLink="/single-services">SEO Optimization</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                                <a routerLink="/single-services" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 p-0">
                            <div class="image bg-2">
                                <img src="assets/img/marketing-agency/services-img2.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="row m-0">
                        <div class="col-lg-6 col-md-12 p-0">
                            <div class="content">
                                <h3><a routerLink="/single-services">Advanced Analytics</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                                <a routerLink="/single-services" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 p-0">
                            <div class="image bg-3">
                                <img src="assets/img/marketing-agency/services-img3.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="row m-0">
                        <div class="col-lg-6 col-md-12 p-0">
                            <div class="content">
                                <h3><a routerLink="/single-services">Email Marketing</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                                <a routerLink="/single-services" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 p-0">
                            <div class="image bg-4">
                                <img src="assets/img/marketing-agency/services-img4.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="pagination-area">
                    <a routerLink="/services-3" class="prev page-numbers"><i class='bx bxs-arrow-to-left'></i></a>
                    <a routerLink="/services-3" class="page-numbers">1</a>
                    <span class="page-numbers current" aria-current="page">2</span>
                    <a routerLink="/services-3" class="page-numbers">3</a>
                    <a routerLink="/services-3" class="page-numbers">4</a>
                    <a routerLink="/services-3" class="next page-numbers"><i class='bx bxs-arrow-to-right'></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->

<!-- Start Subscribe Area -->
<section class="subscribe-area bg-f4f5fe">
    <div class="container">
        <div class="subscribe-content">
            <h2>We always try to be the best support to you as possible</h2>

            <form class="newsletter-form">
                <div class="row align-items-center">
                    <div class="col-lg-8 col-md-8">
                        <input type="email" class="input-newsletter" placeholder="hello@spacle.com" name="EMAIL">
                    </div>

                    <div class="col-lg-4 col-md-4">
                        <button type="submit"><i class="bx bxs-hot"></i> Subscribe Now</button>
                    </div>
                </div>
            </form>

            <div class="shape14"><img src="assets/img/shape/13.png" alt="image"></div>
            <div class="shape15"><img src="assets/img/shape/14.png" alt="image"></div>
            <div class="shape16"><img src="assets/img/shape/15.png" alt="image"></div>
            <div class="shape17"><img src="assets/img/shape/16.png" alt="image"></div>
            <div class="shape18"><img src="assets/img/shape/17.png" alt="image"></div>
        </div>
    </div>
</section>
<!-- End Subscribe Area -->

<app-clients></app-clients>