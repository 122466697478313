<app-preloader></app-preloader>

<ng-container *ngIf="(location == '/auth/signup' || location == '/auth/signin' || location == '/error-404'); else second">
    <router-outlet></router-outlet>
</ng-container>

<ng-template #second>
    <ng-container *ngIf="(location == '/home' || location == '/home-two' || location == '/home-three' || location == '/home-four' || location == '/home-five' || location == '/home-six'); else third">
        <app-header></app-header>
        <router-outlet></router-outlet>
        <app-footer></app-footer>
    </ng-container>
</ng-template>

<ng-template #third>
    <ng-container>
        <app-header-two></app-header-two>
        <router-outlet></router-outlet>
        <app-footer></app-footer>
    </ng-container>
</ng-template>

<!-- <ng-container *ngIf="!(location == '/auth/signup' || location == '/auth/signin' || location == '/error-404')">
    <app-header></app-header>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
</ng-container> -->


<!-- If you want to show navigation in all pages -->
<!--
<ng-container>
    <app-header></app-header>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
</ng-container>
-->