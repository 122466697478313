<!-- Start Marketing Experts Banner Area -->
<section class="me-banner-area">
    <div class="container-fluid">
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="me-banner-content">
                    <span>Welcome To Spacle</span>
                    <h1>We are Creative Marketing Experts</h1>
                    <p>Donec sollicitudin molestie malesuada mauris blandit aliquet elit eget tincidunt nibh pulvinar nulla quis lorem ut libero malesuada feugiat.</p>
                    <ul class="banner-btn">
                        <li>
                            <a routerLink="/contact" class="default-btn">
                                <i class="bx bxs-hot"></i>Get Started<span></span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"> 
                                <i class='bx bxs-right-arrow'></i> Watch Video 
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="me-banner-image">
                    <img src="assets/img/marketing-experts/banner/main.png" alt="image">
                    <div class="circle-shape">
                        <img src="assets/img/marketing-experts/banner/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="me-banner-shape">
        <img src="assets/img/marketing-experts/banner/shape.png" alt="image">
    </div>
    <div class="me-lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>
<!-- End Marketing Experts Banner Area -->

<!-- Start SM Partner Area -->
<div class="sm-partner-area me-partner pt-100 pb-70">
    <div class="container">
        <div class="section-title-with-large-box">
            <span>Trusted By</span>
        </div>
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-2 col-6 col-sm-3 col-md-4">
                <div class="sm-partner-box">
                    <a href="#" target="_blank">
                        <img src="assets/img/it-solution/partner/partner1.png" alt="image">
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-4">
                <div class="sm-partner-box">
                    <a href="#" target="_blank">
                        <img src="assets/img/it-solution/partner/partner2.png" alt="image">
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-4">
                <div class="sm-partner-box">
                    <a href="#" target="_blank">
                        <img src="assets/img/it-solution/partner/partner3.png" alt="image">
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-4">
                <div class="sm-partner-box">
                    <a href="#" target="_blank">
                        <img src="assets/img/it-solution/partner/partner4.png" alt="image">
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-4">
                <div class="sm-partner-box">
                    <a href="#" target="_blank">
                        <img src="assets/img/it-solution/partner/partner5.png" alt="image">
                    </a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-4">
                <div class="sm-partner-box">
                    <a href="#" target="_blank">
                        <img src="assets/img/it-solution/partner/partner6.png" alt="image">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End SM Partner Area -->

<!-- Start ME Features Area -->
<div class="me-features-area pt-100 pb-70">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6">
                <div class="me-features-card">
                    <i class="bx bx-customize"></i>
                    <h3>
                        <a routerLink="/single-services">Data Analysis</a>
                    </h3>
                    <p>Curabitur non nulla sit amet nisl tempus convallis quis ac lectus sed porttitor lectus nibh.</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="me-features-card bg-two">
                    <i class="bx bx-slider-alt"></i>
                    <h3>
                        <a routerLink="/single-services">PPC Marketing</a>
                    </h3>
                    <p>Curabitur non nulla sit amet nisl tempus convallis quis ac lectus sed porttitor lectus nibh.</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="me-features-card bg-three">
                    <i class="bx bx-reset"></i>
                    <h3>
                        <a routerLink="/single-services">Business Analytics</a>
                    </h3>
                    <p>Curabitur non nulla sit amet nisl tempus convallis quis ac lectus sed porttitor lectus nibh.</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="me-features-card bg-four">
                    <i class="bx bx-shape-circle"></i>
                    <h3>
                        <a routerLink="/single-services">Digital Marketing</a>
                    </h3>
                    <p>Curabitur non nulla sit amet nisl tempus convallis quis ac lectus sed porttitor lectus nibh.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End ME Features Area -->

<!-- Start ME About Area -->
<div class="me-about-area pb-100">
    <div class="container pt-100">
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="me-about-image">
                    <img src="assets/img/marketing-experts/about/about.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="me-about-content">
                    <span>What We Do</span>
                    <h3>Spacle is a leading web and app development company</h3>
                    <p>Nulla quis lorem ut libero malesuada feugiat quisque velit nisi pretium ut lacinia in elementum id enim curabitur arcu erat accumsan id imperdiet et porttitor at sem curabitur aliquet quam id dui posuere blandit.</p>
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-6">
                            <ul class="list">
                                <li><i class='bx bx-check'></i> First Step Planing</li>
                                <li class="color-two"><i class='bx bx-check'></i> Third Step Optimizing</li>
                            </ul>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <ul class="list">
                                <li class="color-three"><i class='bx bx-check'></i> Second Step Research</li>
                                <li class="color-four"><i class='bx bx-check'></i> Final Step Results</li>
                            </ul>
                        </div>
                    </div>
                    <div class="about-btn">
                        <a routerLink="/about" class="default-btn">Learn More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="me-about-shape1">
        <img src="assets/img/marketing-experts/about/shape1.png" alt="image">
    </div>
    <div class="me-about-shape2">
        <img src="assets/img/marketing-experts/about/shape2.png" alt="image">
    </div>
</div>
<!-- End ME About Area -->

<!-- Start SM Funfacts Area -->
<div class="sm-funfacts-area me-funfacts">
    <div class="container">
        <div class="sm-funfacts-inner-box bg-with-linear-gradient pt-100 pb-70">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-6 col-sm-6 col-md-3">
                    <div class="sm-single-funfacts">
                        <i class="bx bx-list-check"></i>
                        <h3>
                            <span class="odometer" data-count="800">00</span>
                            <span class="sign-icon">+</span>
                        </h3>
                        <p>Completed Projects</p>
                    </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-6 col-md-3">
                    <div class="sm-single-funfacts">
                        <i class="bx bx-smile"></i>
                        <h3>
                            <span class="odometer" data-count="999">00</span>
                            <span class="sign-icon">+</span>
                        </h3>
                        <p>Happy Clients</p>
                    </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-6 col-md-3">
                    <div class="sm-single-funfacts">
                        <i class="bx bx-grid-small"></i>
                        <h3>
                            <span class="odometer" data-count="120">00</span>
                            <span class="sign-icon">+</span>
                        </h3>
                        <p>Ongoing Projects</p>
                    </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-6 col-md-3">
                    <div class="sm-single-funfacts">
                        <i class="bx bxs-award"></i>
                        <h3>
                            <span class="odometer" data-count="70">00</span>
                            <span class="sign-icon">+</span>
                        </h3>
                        <p>Winning Awards</p>
                    </div>
                </div>
            </div>
            <div class="funfacts-shape">
                <img src="assets/img/marketing-experts/funfacts-shape.png" alt="image">
            </div>
        </div>
    </div>
</div>
<!-- End SM Funfacts Area -->

<!-- Start IS Services Area -->
<div class="is-services-area ptb-100">
    <div class="container-fluid">
        <div class="section-title-with-large-box">
            <span>Introduce our services</span>
            <h2>Why your Businesses need Our Best services</h2>
        </div>
        <div class="is-services-slides owl-carousel owl-theme">
            <div class="is-services-card">
                <h3>
                    <a routerLink="/single-services">Web Development</a>
                </h3>
                <div class="is-services-image">
                    <a routerLink="/single-services">
                        <img src="assets/img/marketing-experts/services/services1.jpg" alt="image">
                    </a>
                </div>
                <p>Pellentesque in ipsum id orci porta dapibus nulla quis ut libero malesuada feugiat donec sollicitudin molestie malesuada.</p>
                <a routerLink="/single-services" class="default-btn">Learn More <span></span></a>
            </div>
            <div class="is-services-card">
                <h3>
                    <a routerLink="/single-services">App Development</a>
                </h3>
                <div class="is-services-image">
                    <a routerLink="/single-services">
                        <img src="assets/img/marketing-experts/services/services2.jpg" alt="image">
                    </a>
                </div>
                <p>Pellentesque in ipsum id orci porta dapibus nulla quis ut libero malesuada feugiat donec sollicitudin molestie malesuada.</p>
                <a routerLink="/single-services" class="default-btn">Learn More <span></span></a>
            </div>
            <div class="is-services-card">
                <h3>
                    <a routerLink="/single-services">Blockchain Development</a>
                </h3>
                <div class="is-services-image">
                    <a routerLink="/single-services">
                        <img src="assets/img/marketing-experts/services/services3.jpg" alt="image">
                    </a>
                </div>
                <p>Pellentesque in ipsum id orci porta dapibus nulla quis ut libero malesuada feugiat donec sollicitudin molestie malesuada.</p>
                <a routerLink="/single-services" class="default-btn">Learn More <span></span></a>
            </div>
            <div class="is-services-card">
                <h3>
                    <a routerLink="/single-services">Branding and Marketing</a>
                </h3>
                <div class="is-services-image">
                    <a routerLink="/single-services">
                        <img src="assets/img/marketing-experts/services/services4.jpg" alt="image">
                    </a>
                </div>
                <p>Pellentesque in ipsum id orci porta dapibus nulla quis ut libero malesuada feugiat donec sollicitudin molestie malesuada.</p>
                <a routerLink="/single-services" class="default-btn">Learn More <span></span></a>
            </div>
            <div class="is-services-card">
                <h3>
                    <a routerLink="/single-services">Web Development</a>
                </h3>
                <div class="is-services-image">
                    <a routerLink="/single-services">
                        <img src="assets/img/marketing-experts/services/services1.jpg" alt="image">
                    </a>
                </div>
                <p>Pellentesque in ipsum id orci porta dapibus nulla quis ut libero malesuada feugiat donec sollicitudin molestie malesuada.</p>
                <a routerLink="/single-services" class="default-btn">Learn More <span></span></a>
            </div>
            <div class="is-services-card">
                <h3>
                    <a routerLink="/single-services">App Development</a>
                </h3>
                <div class="is-services-image">
                    <a routerLink="/single-services">
                        <img src="assets/img/marketing-experts/services/services2.jpg" alt="image">
                    </a>
                </div>
                <p>Pellentesque in ipsum id orci porta dapibus nulla quis ut libero malesuada feugiat donec sollicitudin molestie malesuada.</p>
                <a routerLink="/single-services" class="default-btn">Learn More <span></span></a>
            </div>
            <div class="is-services-card">
                <h3>
                    <a routerLink="/single-services">Blockchain Development</a>
                </h3>
                <div class="is-services-image">
                    <a routerLink="/single-services">
                        <img src="assets/img/marketing-experts/services/services3.jpg" alt="image">
                    </a>
                </div>
                <p>Pellentesque in ipsum id orci porta dapibus nulla quis ut libero malesuada feugiat donec sollicitudin molestie malesuada.</p>
                <a routerLink="/single-services" class="default-btn">Learn More <span></span></a>
            </div>
            <div class="is-services-card">
                <h3>
                    <a routerLink="/single-services">Branding and Marketing</a>
                </h3>
                <div class="is-services-image">
                    <a routerLink="/single-services">
                        <img src="assets/img/marketing-experts/services/services4.jpg" alt="image">
                    </a>
                </div>
                <p>Pellentesque in ipsum id orci porta dapibus nulla quis ut libero malesuada feugiat donec sollicitudin molestie malesuada.</p>
                <a routerLink="/single-services" class="default-btn">Learn More <span></span></a>
            </div>
        </div>
    </div>
    <div class="me-services-shape1">
        <img src="assets/img/marketing-experts/services/services-shape1.png" alt="image">
    </div>
    <div class="me-services-shape2">
        <img src="assets/img/marketing-experts/services/services-shape2.png" alt="image">
    </div>
    <div class="me-services-shape3">
        <img src="assets/img/marketing-experts/services/services-shape3.png" alt="image">
    </div>
</div>
<!-- End IS Services Area -->

<!-- Start Me Choose Area -->
<div class="me-choose-area ptb-100">
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="me-choose-content">
                    <span>Why Choose Us</span>
                    <h3>We are committed to providing our services</h3>
                    <p>Curabitur arcu erat accumsan id imperdiet et porttitor at sem sed porttitor lectus nibh vivamus magna justo lacinia eget consectetur sed tellus.</p>
                    <div class="choose-inner-card">
                        <i class='bx bx-check'></i>
                        <h4>Transparent</h4>
                        <p>Accumsan id imperdiet et porttitor at sem sed porttitor lectus nibh vivamus magna justo lacinia eget consectetur sed convallis at tellus.</p>
                    </div>
                    <div class="choose-inner-card bg-two">
                        <i class='bx bx-check'></i>
                        <h4>Affordable</h4>
                        <p>Curabitur arcu erat accumsan id imperdiet et porttitor at sem sed porttitor lectus nibh vivamus magna justo lacinia eget consectetur sed convallis.</p>
                    </div>
                    <div class="choose-inner-card bg-three">
                        <i class='bx bx-check'></i>
                        <h4>Flexible</h4>
                        <p>accumsan id imperdiet et porttitor at sem sed porttitor lectus nibh vivamus magna justo</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="me-choose-image">
                    <img src="assets/img/marketing-experts/choose.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Me Choose Area -->

<!-- Start SM Subscribe Area -->
<div class="sm-subscribe-area me-subscribe">
    <div class="container">
        <div class="sm-subscribe-inner-box with-linear-gradient-color ptb-100">
            <div class="section-title-with-large-box">
                <h2>Join our growing community for all the privacy of the industry!</h2>
            </div>
            <form class="newsletter-form" data-bs-toggle="validator">
                <div class="form-group">
                    <input type="email" class="input-newsletter" placeholder="hello@spacle.com" name="EMAIL" required autocomplete="off">
                </div>
                <button type="submit" class="disabled" style="pointer-events: all; cursor: pointer;">
                    <i class='bx bx-send'></i>
                </button>
            </form>
            <div class="sm-subscribe-shape-1">
                <img src="assets/img/seo-marketing/subscribe-shape1.png" alt="image">
            </div>
            <div class="sm-subscribe-shape-2">
                <img src="assets/img/seo-marketing/subscribe-shape2.png" alt="image">
            </div>
        </div>
    </div>
</div>
<!-- End SM Subscribe Area -->

<!-- Start IS Team Area -->
<div class="is-team-area me-team ptb-100">
    <div class="container">
        <div class="section-title-with-large-box">
            <span>Our Team</span>
            <h2>Meet Our Leadership Preparing For Your Success</h2>
        </div>
        <div class="row justify-content-center is-inner-team-box">
            <div class="col-lg-3 col-md-6">
                <div class="is-team-member">
                    <div class="image">
                        <img src="assets/img/it-solution/team/team1.jpg" alt="team-image">
                        <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>
                    <div class="content with-position-relative">
                        <h3>James Anderson</h3>
                        <i class='bx bx-share-alt'></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="is-team-member">
                    <div class="image">
                        <img src="assets/img/it-solution/team/team2.jpg" alt="team-image">
                        <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>
                    <div class="content with-position-relative">
                        <h3>Sarah Taylor</h3>
                        <i class='bx bx-share-alt'></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="is-team-member">
                    <div class="image">
                        <img src="assets/img/it-solution/team/team3.jpg" alt="team-image">
                        <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>
                    <div class="content with-position-relative">
                        <h3>Taylor Sopia</h3>
                        <i class='bx bx-share-alt'></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="is-team-member">
                    <div class="image">
                        <img src="assets/img/it-solution/team/team4.jpg" alt="team-image">
                        <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>
                    <div class="content with-position-relative">
                        <h3>Harry Steve</h3>
                        <i class='bx bx-share-alt'></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="me-team-shape">
        <img src="assets/img/marketing-experts/team-shape.png" alt="image">
    </div>
</div>
<!-- End IS Team Area -->

<!-- Start SM Pricing Area -->
<div class="sm-pricing-area pt-100 pb-70">
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="sm-pricing-section-content">
                    <span>Pricing Plan</span>
                    <h3>Choose Transparent pricing Plan</h3>
                    <p>Pellentesque in ipsum id orci porta dapibus nulla quis lorem ut libero malesuada feugiat donec sollicitudin molestie malesuada.</p>
                    <a routerLink="/contact" class="default-btn">Ask A Quote <span></span></a>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="row justify-content-center">
                    <div class="col-lg-6 col-md-6">
                        <div class="sm-pricing-card with-black-color">
                            <div class="pricing-header">
                                <h3>Platinum Plan</h3>
                            </div>
                            <div class="price">
                                $49 <span>/Per Month</span>
                            </div>
                            <ul class="pricing-features">
                                <li>
                                    <i class='bx bx-check-circle'></i>
                                    10 Pages responsive website
                                </li>
                                <li>
                                    <i class='bx bx-check-circle'></i>
                                    Audience research
                                </li>
                                <li>
                                    <i class='bx bx-check-circle'></i>
                                    Management & optimisation
                                </li>
                                <li>
                                    <i class='bx bx-check-circle'></i>
                                    50 SEO keywords
                                </li>
                                <li>
                                    <i class='bx bx-check-circle'></i>
                                    Google analytics tracking
                                </li>
                                <li>
                                    <i class='bx bx-check-circle'></i>
                                    Consultancy
                                </li>
                                <li>
                                    <i class='bx bx-check-circle'></i>
                                    24x7 Great support
                                </li>
                            </ul>
                            <div class="price-btn">
                                <a routerLink="/" class="default-btn">Select Plan<span></span></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="sm-pricing-card with-black-color">
                            <div class="pricing-header">
                                <h3>Advance Plan</h3>
                            </div>
                            <div class="price">
                                $99 <span>/Per Month</span>
                            </div>
                            <ul class="pricing-features">
                                <li>
                                    <i class='bx bx-check-circle'></i>
                                    10 Pages responsive website
                                </li>
                                <li>
                                    <i class='bx bx-check-circle'></i>
                                    Audience research
                                </li>
                                <li>
                                    <i class='bx bx-check-circle'></i>
                                    Management & optimisation
                                </li>
                                <li>
                                    <i class='bx bx-check-circle'></i>
                                    50 SEO keywords
                                </li>
                                <li>
                                    <i class='bx bx-check-circle'></i>
                                    Google analytics tracking
                                </li>
                                <li>
                                    <i class='bx bx-check-circle'></i>
                                    Consultancy
                                </li>
                                <li>
                                    <i class='bx bx-check-circle'></i>
                                    24x7 Great support
                                </li>
                            </ul>
                            <div class="price-btn">
                                <a routerLink="/" class="default-btn">Select Plan<span></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="me-pricing-shape1">
        <img src="assets/img/marketing-experts/pricing-shape1.png" alt="image">
    </div>
    <div class="me-pricing-shape2">
        <img src="assets/img/marketing-experts/pricing-shape2.png" alt="image">
    </div>
</div>
<!-- End SM Pricing Area -->

<!-- Start IS Testimonials Area -->
<div class="is-testimonials-area ptb-100">
    <div class="container">
        <div class="section-title-with-large-box">
            <span>Testimonials</span>
            <h2>Our Customer Valuable Feedback</h2>
        </div>
        <div class="is-testimonials-slides owl-carousel owl-theme">
            <div class="is-testimonials-card">
                <p>“Nulla quis lorem ut libero malesuada feugiat quisque velit nisi pretium ut lacinia in elementum id enim. curabitur arcu erat accumsan id imperdiet et porttitor. curabitur aliquet quam id dui posuere blandit.”</p>
                <div class="client-info d-flex align-items-center">
                    <img src="assets/img/author-image/1.jpg" alt="image">
                    <div class="title">
                        <h3>George Stewart</h3>
                        <span>CEO & Founder</span>
                    </div>
                </div>
            </div>
            <div class="is-testimonials-card">
                <p>“Nulla quis lorem ut libero malesuada feugiat quisque velit nisi pretium ut lacinia in elementum id enim. curabitur arcu erat accumsan id imperdiet et porttitor. curabitur aliquet quam id dui posuere blandit.”</p>
                <div class="client-info d-flex align-items-center">
                    <img src="assets/img/author-image/2.jpg" alt="image">
                    <div class="title">
                        <h3>Jose Covert</h3>
                        <span>Web Designer</span>
                    </div>
                </div>
            </div>
            <div class="is-testimonials-card">
                <p>“Nulla quis lorem ut libero malesuada feugiat quisque velit nisi pretium ut lacinia in elementum id enim. curabitur arcu erat accumsan id imperdiet et porttitor. curabitur aliquet quam id dui posuere blandit.”</p>
                <div class="client-info d-flex align-items-center">
                    <img src="assets/img/author-image/3.jpg" alt="image">
                    <div class="title">
                        <h3>Karen Wilson</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>
            <div class="is-testimonials-card">
                <p>“Nulla quis lorem ut libero malesuada feugiat quisque velit nisi pretium ut lacinia in elementum id enim. curabitur arcu erat accumsan id imperdiet et porttitor. curabitur aliquet quam id dui posuere blandit.”</p>
                <div class="client-info d-flex align-items-center">
                    <img src="assets/img/author-image/1.jpg" alt="image">
                    <div class="title">
                        <h3>George Stewart</h3>
                        <span>CEO & Founder</span>
                    </div>
                </div>
            </div>
            <div class="is-testimonials-card">
                <p>“Nulla quis lorem ut libero malesuada feugiat quisque velit nisi pretium ut lacinia in elementum id enim. curabitur arcu erat accumsan id imperdiet et porttitor. curabitur aliquet quam id dui posuere blandit.”</p>
                <div class="client-info d-flex align-items-center">
                    <img src="assets/img/author-image/2.jpg" alt="image">
                    <div class="title">
                        <h3>Jose Covert</h3>
                        <span>Web Designer</span>
                    </div>
                </div>
            </div>
            <div class="is-testimonials-card">
                <p>“Nulla quis lorem ut libero malesuada feugiat quisque velit nisi pretium ut lacinia in elementum id enim. curabitur arcu erat accumsan id imperdiet et porttitor. curabitur aliquet quam id dui posuere blandit.”</p>
                <div class="client-info d-flex align-items-center">
                    <img src="assets/img/author-image/3.jpg" alt="image">
                    <div class="title">
                        <h3>Karen Wilson</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="me-testimonials-shape1">
        <img src="assets/img/marketing-experts/testimonials-shape1.png" alt="image">
    </div>
    <div class="me-testimonials-shape2">
        <img src="assets/img/marketing-experts/testimonials-shape2.png" alt="image">
    </div>
</div>
<!-- End IS Testimonials Area -->

<!-- Start SM Blog Area -->
<div class="sm-blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title-with-large-box">
            <span>Latest Blog</span>
            <h2>Check our Latest & Trending Blogs</h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="sm-blog-card">
                    <div class="blog-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/seo-marketing/blog/blog1.jpg" alt="image">
                        </a>
                    </div>
                    <div class="blog-content">
                        <ul class="meta">
                            <li>
                                <a routerLink="/blog-grid">
                                    <img src="assets/img/user1.jpg" alt="image">
                                    Angela Carter
                                </a>
                            </li>
                            <li><i class='bx bx-calendar'></i> 14 Feb, 2022</li>
                        </ul>    
                        <h3>
                            <a routerLink="/blog-details">Some strategies to attract new customers</a>
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="sm-blog-card">
                    <div class="blog-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/seo-marketing/blog/blog2.jpg" alt="image">
                        </a>
                    </div>
                    <div class="blog-content">
                        <ul class="meta">
                            <li>
                                <a routerLink="/blog-grid">
                                    <img src="assets/img/user2.jpg" alt="image">
                                    Angela Carter
                                </a>
                            </li>
                            <li><i class='bx bx-calendar'></i> 14 Feb, 2022</li>
                        </ul>    
                        <h3>
                            <a routerLink="/blog-details">How to make your business successful</a>
                        </h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="sm-blog-card">
                    <div class="blog-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/seo-marketing/blog/blog3.jpg" alt="image">
                        </a>
                    </div>
                    <div class="blog-content">
                        <ul class="meta">
                            <li>
                                <a routerLink="/blog-grid">
                                    <img src="assets/img/user3.jpg" alt="image">
                                    Angela Carter
                                </a>
                            </li>
                            <li><i class='bx bx-calendar'></i> 14 Feb, 2022</li>
                        </ul>    
                        <h3>
                            <a routerLink="/blog-details">What are the strategies used in a digital marketing?</a>
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="sm-blog-shape">
        <img src="assets/img/seo-marketing/blog/shape.png" alt="image">
    </div>
</div>
<!-- End SM Blog Area -->